import {
  FIELD_ICONS,
  FIELD_TYPES,
  NUMERIC_FORMATS,
} from "@circle-react-shared/ProfileFields/constants";

export { FIELD_TYPES, FIELD_ICONS, NUMERIC_FORMATS };

export const DESCRIPTION_FIELD_INPUTS = {
  LABEL: "label",
  FIELD_TYPE: "field_type",
  KEY: "key",
  PLACEHOLDER: "placeholder",
  DESCRIPTION: "description",
};
export const DEFAULT_FIELD_INPUTS = {
  REQUIRED: "required",
  HIDDEN: "hidden",
};

export const FIELD_INPUTS = Object.values({
  ...DESCRIPTION_FIELD_INPUTS,
  ...DEFAULT_FIELD_INPUTS,
});
export const PAGES_ATTRIBUTES = "pages_attributes";

export const FIELD_TYPE_SPECIFIC_INPUTS: any = {
  [FIELD_TYPES.TEXT]: {},
  [FIELD_TYPES.SELECT]: {
    CHOICES_ATTRIBUTES: "choices_attributes",
  },
  [FIELD_TYPES.CHECKBOX]: {
    CHOICES_ATTRIBUTES: "choices_attributes",
  },
  [FIELD_TYPES.TEXTAREA]: {},
  [FIELD_TYPES.LINK]: {},
  [FIELD_TYPES.LOCATION]: {},
  [FIELD_TYPES.NUMBER]: {
    NUMBER_OPTIONS: "number_options_attributes",
  },
};

export const FIXED_FIELD_ICONS = [
  "twitter",
  "facebook",
  "instagram",
  "linkedin",
];

export const NUMERIC_DECIMALS = [0, 1, 2, 3, 4, 5];

export const TABS = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};
